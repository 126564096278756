import { Component, OnInit, HostListener } from '@angular/core';
import { trigger, transition, query, style, stagger, animate, state } from '@angular/animations';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-images',
  templateUrl: './images.component.html',
  styleUrls: ['./images.component.scss'],
  animations: [
    trigger('listStagger', [
      transition('* <=> *', [
        query(
          ':enter',
          [
            style({ opacity: 0, transform: 'translateY(-15px)' }),
            stagger(
              '100ms',
              animate(
                '550ms ease-out',
                style({ opacity: 1, transform: 'translateY(0px)' })
              )
            )
          ],
          { optional: true }
        ),
        query(':leave', animate('50ms', style({ opacity: 0 })), {
          optional: true
        })
      ])
    ]),
    trigger('scale',[
      transition("void => *",[
        style({opacity: 0, transform: "scale(0.5)"}),
        animate("300ms cubic-bezier(0.785, 0.135, 0.15, 0.86)",
        style({ opacity: 1, transform: "scale(1)" }))
        ])
    ])
  ]
})
export class ImagesComponent implements OnInit {

  images: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44];
  selectedIndex: number = 1;
  amountCols = 4;
  buttonsVisible = true;

  selectedImageStyle: Object = { border: 'solid 5px rgb(235, 161, 25)' };
  notSelectedImageStyle: Object = {};

  constructor(
    private title: Title,
    private meta: Meta
  ) {
    this.title.setTitle("Foto's");
    this.meta.addTag({name: 'description', content: "bekijk hier alle foto's van eerdere edities van de tongerlse dorpsfeesten."});
    this.meta.addTag({ name: 'keywords', content: "Tongels dorpsfeest foto's, tongelse dorpsfeesten foto's, tongels dorpsfeest gallerij"})

    let width = window.innerWidth;
    if (width <= 500) {
      this.amountCols = 3;
    }
  }

  async ngOnInit() {
    await this.setButtonsVisibility();
  }

  async ImageSelected(index: number) {
    this.selectedIndex = (index + 1);
    await this.setButtonsVisibility();
  }

  delay(ms: number){
    return new Promise( resolve => setTimeout(resolve, ms));
  }

  async setButtonsVisibility(){
    this.buttonsVisible = false;
    await this.delay(300);
    this.buttonsVisible = true;
  }

  async nextImage() {
    if (this.selectedIndex == this.images.length) {
      this.selectedIndex = 1;
      await this.setButtonsVisibility();
      return;
    }
    ++this.selectedIndex;
    await this.setButtonsVisibility();
  }

  async prevImage() {
    if (this.selectedIndex == 1) {
      this.selectedIndex = this.images.length;
      await this.setButtonsVisibility();
      return;
    }
    --this.selectedIndex;
    await this.setButtonsVisibility();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    let width = event.target.innerWidth;
    if (width <= 500) {
      this.amountCols = 3;
    } else {
      this.amountCols = 4;
    }
  }
}



