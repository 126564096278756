import { Component, OnInit } from '@angular/core';
import { trigger, transition, style, animate, query, stagger, state } from '@angular/animations';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-tvh',
  templateUrl: './tvh.component.html',
  styleUrls: ['./tvh.component.scss'],
  animations:[
    trigger('listStagger', [
      transition('* <=> *', [
        query(
          ':enter',
          [
            style({ opacity: 0, transform: 'translateY(-15px)' }),
            stagger(
              '100ms',
              animate(
                '550ms ease-out',
                style({ opacity: 1, transform: 'translateY(0px)' })
              )
            )
          ],
          { optional: true }
        ),
        query(':leave', animate('50ms', style({ opacity: 0 })), {
          optional: true
        })
      ])
    ]),
    trigger('popOverState',[
      state('show',style({
        opacity: 1
      })),
      state('hide',style({
        opacity: 0
      })),
      transition('show => hide', animate('600ms ease-out')),
      transition('hide => show', animate('800ms ease-in'))
    ])
  ]
})
export class TvhComponent implements OnInit {

  constructor(
    private title: Title,
    private meta: Meta
  ) {
    this.title.setTitle("Tongerlo Leeft!");
    this.meta.addTag({name: 'description', content: "De Tongelse Dorpsfeesten zijn ontstaan in 1987. Het wordt georganiseerd door een comite dat bestaat uit 6 personen."});
    this.meta.addTag({ name: 'keywords', content: "Tongelse Dorpsfeesten, organisatoren, tvh, oprichters"})
   }

  groups : Array<{name:string,link:string,show:boolean}>;

  ngOnInit() {
    this.groups = [{name:"HSV",link:"https://www.facebook.com/pages/category/Soccer-Field/HSV-Tongerlo-832768450409166/",show:false},
    {name:"FIEN BOYS",link:"http://fienboys.be/",show:false},
    {name:"KFC TONGERLO",link:"https://www.kfctongerlo.be/",show:false},
    {name:"CHIRO",link:"http://www.chirotongerlo.be/",show:false},
    {name:"KLJ",link:"http://kljtongerlo.be/",show:false},
    {name:"LANDELIJKE GILDE",link:"http://www.lgtongerlo.be/",show:false},
    {name:"VOLLEY WESTERLO",link:"https://www.volleywesterlo.be/",show:false},
    {name:"T-BLAZERS",link:"https://www.facebook.com/fanfareTblazers/",show:false},
    {name:"KKSVWO",link:"https://www.kksvwo.be/",show:false}
      ]
  }

  stateName(i : any) : string{
    return this.groups[i].show ? 'show' : 'hide';
  }

  toggle(i: any){
    this.groups[i].show = !this.groups[i].show;
  }
}
