import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { ConcertComponent } from './components/concert/concert.component';
import { ContactComponent } from './components/contact/contact.component';
import { TvhComponent } from './components/tvh/tvh.component';
import { ImagesComponent } from './components/images/images.component';
import { PracticalInfoComponent } from './components/practical-info/practical-info.component';


const routes: Routes = [
  {path:"",redirectTo:"home",pathMatch:"full"},
  {path:"home",component: HomeComponent},
  {path:"images",component: ImagesComponent},
  {path:"concert",component: ConcertComponent},
  {path:"contact",component: ContactComponent},
  {path:"tongerloleeft",component: TvhComponent},
  {path:"info",component: PracticalInfoComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
